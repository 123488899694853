<template>
    <div id="relatorio">
        <div class="filter">
            <div class="header">
                <div class="filter-label">
                    Exibindo dados de <span>{{ filteredDay }}</span>
                </div>
                <div class="button-set">
                    <Button icon="pi pi-calendar" class="p-button-sm" iconPos="right"  @click="visible.filterDate = !visible.filterDate"/>
                    <Button icon="pi pi-print" class="p-button-sm" iconPos="right"  @click="visible.print = true"/>
                    <Button icon="pi pi-refresh" class="p-button-sm gray" @click="resetFilter()"/>
                </div>
            </div>
            <Transition name="grow">
                <Calendar dateFormat="yy-mm-dd" @date-select="filterDate()" v-if="visible.filterDate" v-model="filterDay" :inline="true" :maxDate="new Date()"/>
            </Transition>
        </div>

        <Transition name="grow">
            <fieldset class="resume" v-if="!this.loading.table">
            <legend><i class="pi pi-chart-bar"></i>Resumo do dia</legend>
            <p>
                <span class="label"><i class="pi pi-arrow-down"></i> Entrada</span>
                <span class="value">{{ entradaTotal() }}</span>
            </p>
            <p>
                <span class="label"><i class="pi pi-arrow-up"></i> Saída</span>
                <span class="value">{{ saidaTotal() }}</span>
            </p>
            <p>
                <span class="label"><i class="pi pi-chart-pie"></i> Total</span>
                <span class="value">{{ movimentacaoTotal() }}</span>
            </p>
        </fieldset>
        </Transition>
        <div p v-if="false"><div g></div></div>
        
        <DataTable
            stripedRows
            :value="extract"
            autoLayout
            :loading="loading.table"
            responsiveLayout="scroll"
        >
            <template #empty>
                Nenhum dado encontrado.
            </template>
            <template #loading>
                <Skeleton height="30px" style="margin: 15px 0"/>
                <Skeleton height="30px" style="margin: 15px 0"/>
                <Skeleton height="30px" style="margin: 15px 0"/>
            </template>

            <Column header="Ponto" field="name">
                <template #body="extract">
                    <div>
                        {{ extract.data.name }}
                    </div>
                </template>
            </Column>
<!--            <Column field="type">
                <template #header>
                    <i class="pi pi-sort-alt"></i>
                </template>
                <template #body="extract">
                    
                </template>
            </Column>-->
            <Column header="Valor" field="value">
                <template #body="extract">
                    <div class="value" :class="getExtractTypeArrow(extract.data.type,true)" style="text-align: right">
                        <i class="pi " :class="getExtractTypeArrow(extract.data.type)" ></i>
                        <span>{{ toMoney(extract.data.value) }}</span>
                    </div>
                </template>
            </Column>

            <Column field="date">
                <template #header>
                    <div style="text-align: center;width:100%">
                        <i class="pi pi-clock"></i>
                    </div>
                </template>
                <template #body="extract">
                    <div>
                        {{ extract.data.date }}
                    </div>
                </template>
            </Column>

        </DataTable>

        <Sidebar v-model:visible="visible.print" position="top" class="print-sidebar">
            <div class="print-setup">
                <div class="settings">
                    <div>
                        Tipo de Impressão
                        <SelectButton v-model="print.type" :options="print.types" />
                    </div>
                </div>
                <Button class="connected" v-if="print.connected && print.type == 'Tipo 2'">
                    <div>Conectado</div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                </Button>

                <Button class="connect" v-else-if="print.type == 'Tipo 2'" @click="connect">
                    <div>Conectar</div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M196.48 260.023l92.626-103.333L143.125 0v206.33l-86.111-86.111-31.406 31.405 108.061 108.399L25.608 368.422l31.406 31.405 86.111-86.111L145.84 512l148.552-148.644-97.912-103.333zm40.86-102.996l-49.977 49.978-.338-100.295 50.315 50.317zM187.363 313.04l49.977 49.978-50.315 50.316.338-100.294z"/></svg>
                </Button>
                <div v-if="(print.type == 'Tipo 2' && print.connected) || print.type == 'Tipo 1'">
                    <Button :disabled="extract.length <= 0" @click="handlePrint">Imprimir &nbsp; <i class="pi pi-print"></i></Button>
                </div>    
                <div>
                    <div v-if="!loading.bluetooth">
                        <div v-if="print.type == 'Tipo 1'"><br><hr>Lembre-se de ter instalado e configurado o app <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.a402d.rawbtprinter">RawBT</a></div>
                        <div v-if="print.type == 'Tipo 2' && !print.connected"><br><hr>Por favor conecte-se a uma impresssora para continuar</div>
                    </div>
                    <div v-else><i class="pi pi-spinner pi-spin"></i> Conectando...</div>
                </div>
                
            </div>
        </Sidebar>
    </div>
</template>

<script>
import { getExtract } from "@/services/api";

import Skeleton from 'primevue/skeleton';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Button from "primevue/button";
import {formatMoney} from "@/services/helper";
import Sidebar from 'primevue/sidebar';
import SelectButton from 'primevue/selectbutton';
import BTP from "@/plugins/BluetoothThermalPrinter";
import EscPosEncoder from '@/plugins/esc-pos-encoder/src/esc-pos-encoder'
import Canvas from "@/plugins/Canvas";

export default {
    components: {
        Skeleton,
        DataTable,
        Column,
        Calendar,
        Button,
        Sidebar,
        SelectButton
    },
    data() {
        return {
            loading: {
                table: false,
                bluetooth: false,
            },
            extract: [],
            visible: {
                filterDate: false,
                print: false,
            },
            filterDay: '',
            print: {
                connected: false,
                types:['Tipo 1','Tipo 2'],
                type: 'Tipo 2',
                characteristics: null,
                gattServer: null
            }
        }
    },
    created() {
        this.loadExtract()
    },
    methods: {
        getExtractTypeArrow(type,onlyColor = false){
              if (type == 'outflow'){
                  return onlyColor ? 'red' :'pi-arrow-up';
              }else{
                  return onlyColor ? 'green' :'pi-arrow-down';
              }
        },
        loadExtract(date = false) {
            this.loading.table = true
            getExtract(date)
            .then((response)=>{
                this.extract = response
            }).catch((err)=>{
                this.error(err)
            }).finally(()=>{
                this.loading.table = false
            })
        },
        filterDate(){
            this.visible.filterDate = false
            this.loadExtract(Date.parse(this.filterDay))
        },
        resetFilter(){
            this.filterDay = ''
            this.loadExtract()
        },
        toMoney(val){
            return formatMoney(val)
        },
        entradaTotal(){
            return this.extract.filter(e => e.type == 'income').map(e=>e.value).reduce((anterior, novo)=>{ return anterior + parseFloat(novo) },0).toFixed(2);
        },
        saidaTotal(){
            return this.extract.filter(e => e.type == 'outflow').map(e=>e.value).reduce((anterior, novo)=>{ return anterior + parseFloat(novo) },0).toFixed(2);
        },
        movimentacaoTotal(){
            return this.extract.map((e)=> {
                if (e.type == 'income'){
                    return e.value
                }else {
                    return e.value * -1
                }
            }).reduce((anterior, novo)=>{ return anterior + parseFloat(novo) },0).toFixed(2);

        },
        connect(){
            this.loading.bluetooth = true
            BTP.connect()
            .then((response)=>{
                this.print.connected = true
                this.print.characteristics = response.characteristic
                this.print.gattServer = response.gattServer
            }).catch((err)=>{
                console.log(err)
                this.error("Não foi possível conectar")
            }).finally(() =>{
                this.loading.bluetooth = false
            })
        },
        handlePrint(){
            switch (this.print.type){
                case "Tipo 1":
                    this.printType1()
                    break;
                case "Tipo 2":
                    this.printType2()
                    break;
            }
        },
        printType1(){
            // COM RAWBT

            // Se estiver conectado, desconecta para que o app funcione
            if (this.print.gattServer){
                try{
                    this.print.gattServer.disconnect()
                    this.print.connected = false
                    this.print.characteristics = null
                    this.print.gattServer = null
                }catch (e) {
                    console.log(e)
                }
            }
            
            let date = this.filteredDay
            if (date == 'HOJE'){
                date = new Date().toLocaleDateString().slice(0,5)
            }
            
            let canvas = new Canvas(376,8)
                .line()
                .text('Relatório','center',36)
                .text(date,'center')
                .line()
                .text("Entrada: ",'left',28,this.entradaTotal())
                .text("Saída: ",'left',28,this.saidaTotal())
                .text("Total: ",'left',28,this.movimentacaoTotal())
                .line()

            this.extract.forEach((item)=>{
                if (item.type == 'income'){
                    canvas.text("Entrada: "+ item.value)
                          .text('Ponto: '+ item.name)
                          .line()
                }else{
                    canvas.text("Saida: "+ item.value)
                          .text('Ponto: '+ item.name)
                          .line()
                }
            })
            canvas.line()
                  .text(new Date().toLocaleString(),'center',16)
                  .line()
                  .addHeight()
            try{
                document.location='rawbt:'+ canvas.element.toDataURL()
            }catch (err){
                this.error("Não foi possível imprimir")
            }
        },
        printType2(){
            // COM ESC_POS_ENCONDER

            let bluetooth = this.print.characteristics
            if (!bluetooth || !bluetooth.uuid) {
                console.log('Nao conectado')
                this.print.connected = false
                return;
            }
            
            let encoder = new EscPosEncoder()
            let printer = encoder.initialize().codepage('cp860')
            let date = this.filteredDay
            if (date == 'HOJE'){
                date = new Date().toLocaleDateString().slice(0,5)
            }
            
            let header = [
                printer.rule().encode(),
                printer.codepage('cp860')
                       .align('center')
                       .line('Relatorio',null,2,2)
                       .line(date)
                       .encode(),
                printer.rule().encode(),
            ]
            
            let total = [
                printer.bold(true).text('Entrada: ').bold(false).text(this.entradaTotal()).newline().encode(),
                printer.codepage('cp860').bold(true).text('Saida: ').bold(false).text(this.saidaTotal()).newline().encode(),
                printer.bold(true).text('Total: ').bold(false).text(this.movimentacaoTotal()).newline().encode(),
                printer.rule().encode()
            ]

            let content = []

            this.extract.forEach((item)=>{
                if (item.type == 'income'){
                    content.push(printer.line("Entrada: "+ item.value).line('Ponto: '+ item.name).newline().encode())
                }else{
                    content.push(printer.codepage('cp860').line("Saida: "+ item.value).line('Ponto: '+ item.name).newline().encode())
                }
            })
            
            let footer = [
                printer.rule().encode(),
                printer.align('center').size('small').line(new Date().toLocaleString()).encode(),
                printer.rule().encode(),
            ]


            let chunks = [
                ...header,
                ...total,
                ...content,
                ...footer,
            ]
            
            /**/

            

            function printChunk(i = 0) {
                if (i >= chunks.length) return;
                BTP.Print(bluetooth, chunks[i])
                    .then(()=>{
                        printChunk(i+1)
                    }).catch((err)=>{
                    console.log(err)
                })
            }

            printChunk()
            
        }
    },
    computed: {
        filteredDay() {
            if (!this.filterDay){
                return 'HOJE'
            }else{
                let date = new Date(this.filterDay)
                const today = new Date().toDateString()
                
                if (this.filterDay.toDateString() == today) return 'HOJE'
                
                let mm = date.getMonth() + 1; // Months start at 0!
                let dd = date.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                return `${dd}/${mm}`;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.print-setup {
    margin: 5px 0;
    font-family: 'Poppins',sans-serif;
    color: $color;
    text-align: center;

    .p-button{
        width: 100% !important;
        margin-bottom: 10px 0;
    }

    .pi,svg{
        margin-left: auto;
    }

    .connect,.connected {
        padding-right: 8px;
        text-transform: uppercase;
        width: 150px;
        height: 50px;

        >div{
            margin-right: 10px;
        }

        svg{
            fill: white;
            width: 15px;
        }

        &.connected{
            background-color: green;
            margin-bottom: 10px;
        }
    }

    .settings{
        margin-top: 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .p-selectbutton{
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}

#relatorio{
    .pi-arrow-up,.red{
        color: red;
        font-weight: bold;
    }
    .pi-arrow-down,.green{
        color: green;
        font-weight: bold;
    }
    
    fieldset.resume{
        border-radius: 20px;
        background-color: $color;
        border: 0;
        box-shadow: 0 0 5px #00000055;
        max-width: 400px;
        margin: 10px auto;
        color: white;
        font-family: 'Poppins',sans-serif;
        font-weight: 400 ;
        
        legend{
            font-weight: 600;
            margin-left: 10px;
            padding: 0 5px;
            background: white;
            border-radius:50vw;
            padding:10px 20px;
            height: 40px;
            filter: drop-shadow(0 0 3px #00000055);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color;
            
            i{
                font-size: 20px;
                font-weight: bold;
                margin-right: 10px;
            }
            
        }

        p{
            display: flex;
            padding: 0 20px;
            .label{
                font-weight: 600;
                margin-right: auto;
                
                .pi-arrow-up,.pi-arrow-down {
                    color: #FFffff;
                }
            }
        }

    }
    
    .filter{
        display: flex;
        flex-direction: column;

        .header{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;

            .filter-label{
                font-family: 'Poppins', sans-serif;
                text-align: start;
                flex: 1;
                padding-left: 10px;
                //color: initial;
                font-weight: 400;

                span{
                    font-weight: bold;
                }
            }

            .button-set{
                display: flex;
                justify-content: flex-end;

                button{
                    &:deep(.pi){
                        font-size: 17px;
                    }
                    border-radius: 0;
                    &.gray{
                    background-color: lighten($color,10%);
                    }
                }

                button:first-child{
                    border-radius: 10px 0 0 10px ;
                }

                button:last-child{
                    border-radius: 0 10px 10px 0 ;
                }
            }
        }
    }

    .p-datatable {
        margin-top: 30px;
        
        .value {
            display: flex;
            
            i{
                margin-right: 6px;
            }
        }
    }
}



[p]{
    --width:120px;
    --size:min(40vw,var(--width));
    width:100%;
    height:var(--size);
    overflow:hidden;
    display:flex;
    align-items:flex-start;
    justify-content:center;
}

[g]{
    box-sizing: border-box;
    width: calc(var(--size)*2);
    max-width:calc(var(--width)*2);
    height: calc(var(--size)*2);
    border-radius:50%;
    border-top: calc(var(--size)/4) solid green;
    border-left: calc(var(--size)/4) solid green;
    border-bottom: calc(var(--size)/4) solid red;
    border-right: calc(var(--size)/4) solid red;
    transform:rotate(calc(
        -135deg + (.95 * 0.5turn)
    ));


}

.grow-enter-active,
.grow-leave-active {
    transition: max-height 400ms ease;
    max-height: 500px;
}

.grow-enter-from,
.grow-leave-to {
    max-height: 0;
}

</style>
<style lang="scss">
.print-sidebar.p-sidebar-top{
    height: 55vh;

    div.p-button{
        width: 100% !important;
    }

    .p-sidebar-content{
        padding: 0 50px;
    }    
}
</style>
